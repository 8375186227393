import React from 'react'
import "../scss/components/menuToggle.scss"

const menuToggle = () => {

  const toggleMenu = () => {
    const spToggleLinks = document.querySelector('.spToggleLinks')
    const menuToggleBtn = document.querySelector('.menuToggleBtn')
    if (spToggleLinks.classList.contains('active')) {
      spToggleLinks.className =  'spToggleLinks'
      menuToggleBtn.className =  'menuToggleBtn'
    } else {
      spToggleLinks.className =  'spToggleLinks active'
      menuToggleBtn.className =  'menuToggleBtn active'
    }
  }

  return (
    <button className="menuToggleBtn" onClick={toggleMenu}>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <g transform="translate(-157 -146)">
          <circle className="circle1" cx="6" cy="6" r="6" transform="translate(158 147)" strokeWidth="2"/>
          <circle className="circle2" cx="6" cy="6" r="6" transform="translate(158 165)" strokeWidth="2"/>
          <circle className="circle3"  cx="6" cy="6" r="6" transform="translate(158 183)" strokeWidth="2"/>
          <circle className="circle4" cx="6" cy="6" r="6" transform="translate(176 147)" strokeWidth="2"/>
          <circle className="circle5" cx="6" cy="6" r="6" transform="translate(176 165)" strokeWidth="2"/>
          <circle className="circle6"  cx="6" cy="6" r="6" transform="translate(176 183)" strokeWidth="2"/>
          <circle className="circle7" cx="6" cy="6" r="6" transform="translate(194 147)" strokeWidth="2"/>
          <circle className="circle8" cx="6" cy="6" r="6" transform="translate(194 165)" strokeWidth="2"/>
          <circle className="circle9"  cx="6" cy="6" r="6" transform="translate(194 183)" strokeWidth="2"/>
        </g></svg>
      <span>Menu</span>
    </button>
  )
}

export default menuToggle