import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "ress"
import Header from "./header"
// import MouseStoke from "./mouseStoke"
import "../scss/layouts/common.scss"
import "../scss/components/layout.scss"


const Layout = ({ location, crumbLabel, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const rootPath = `${__PATH_PREFIX__}/`

  return (
    <>
      {/* <MouseStoke /> */}
      {location.pathname !== rootPath &&
        <Header location={location} siteTitle={data.site.siteMetadata.title} />
      }
      <div className="layoutWrap">
        <main>
          {children}
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
