import { StaticQuery, graphql } from "gatsby"
import React from "react"
import ThemeToggle from '../components/themeToggle'
import Nav from '../components/nav'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../scss/components/header.scss"


const Header = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
      }
    `}
    render={data => {
      const rootPath = `${__PATH_PREFIX__}/`
      let header

      if (location.pathname === rootPath) {
        header = (
          <div className="siteTopTitle">
            <AniLink fade duration={1} to="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="289.906" height="336.918" viewBox="0 0 289.906 336.918"><path d="M0,0H289.906L180.212,154.094,289.906,336.918H0Z"/></svg>
            </AniLink>
          </div>
        )
      } else {
        header = (
          <div className="sitePageTitle">
            <AniLink fade duration={1} to="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="289.906" height="336.918" viewBox="0 0 289.906 336.918"><path d="M0,0H289.906L180.212,154.094,289.906,336.918H0Z"/></svg>
            </AniLink>
          </div>
        )
      }
      return(
        <header className="header">
          {header}
          <Nav />
          <ThemeToggle />
        </header>
      )
    }}
  />
)
export default Header