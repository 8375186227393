import React from "react"
import MenuToggle from '../components/menuToggle'
import AniLink from "gatsby-plugin-transition-link/AniLink";

import TwitterIcon from "../images/tw_icon.svg"
import InstagramIcon from "../images/insta_icon.svg"
import "../scss/components/nav.scss"

const spNav = () => {
  return (
    <>
      <nav className="pcNav">
        <ul className="pcNavMenu">
          <li><AniLink fade duration={1} activeClassName="current" className="pcNavMenu_link" to={`/posts`}>Blog</AniLink></li>
          <li><AniLink fade duration={1} activeClassName="current" className="pcNavMenu_link" to={`/profile`}>Profile</AniLink></li>
        </ul>
        <ul className="pcSnsLinks">
          <li>
            <a className="pcSnsLinks_twitter" href="https://twitter.com/kentaro_koga" target="_blank" rel="noopener noreferrer">
              <img src={TwitterIcon} alt="twitterアイコン" />
            </a>
          </li>
          <li>
            <a className="pcSnsLinks_insta" href="https://www.instagram.com/taroken1210/" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt="instagramアイコン" />
            </a>
          </li>
        </ul>
      </nav>

      <nav className="spNav">
        <ul className="spNavLinks">
          <li>
            <AniLink fade duration={1} activeClassName="current" className="spNavLinks_link" to={`/`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="63.412" height="50.316" viewBox="0 0 63.412 50.316"><path d="M-3972.349,48h-7.565V24.827H-3990L-3961,0l29,24.827h-9.246V48h-22.7V34.758h-8.405V48h0Z" transform="translate(3992.706 1.316)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></svg>
              <span>Home</span>
            </AniLink>
          </li>
          <li>
            <AniLink fade duration={1} activeClassName="current" className="spNavLinks_link" to={`/posts`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="57.082" height="56.532" viewBox="0 0 57.082 56.532"><g transform="translate(369.848 -510.214) rotate(45)"><path d="M10.117,0A10.231,10.231,0,0,1,20.233,10.342v24.28L10.117,44.963,0,34.622V10.342A10.231,10.231,0,0,1,10.117,0Z" transform="translate(129.5 594.052)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M21.649,37.24,11.5,47.842Z" transform="translate(127.892 580.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M12.5,38.24l9.824,9.6Z" transform="translate(117.217 576.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M21.649,37.24,11.5,47.842Z" transform="translate(127.892 570.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M12.5,38.24l9.824,9.6Z" transform="translate(117.217 566.188)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M-.014,0H.978V62.948l-1,2.72Z" transform="translate(138.75 594.759)" strokeWidth="2" /></g></svg>
              <span>Blog</span>
            </AniLink>
          </li>
          <li>
            <AniLink fade duration={1} activeClassName="current" className="spNavLinks_link" to={`/profile`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="41.658" height="50" viewBox="0 0 41.658 50"><path d="M-1710,41.536c0-8.618,8.878-19.829,19.829-19.829s19.829,11.21,19.829,19.829c0,4.309-9.914,6.464-19.829,6.464S-1710,45.845-1710,41.536Zm8.975-30.682A10.854,10.854,0,0,1-1690.171,0a10.854,10.854,0,0,1,10.854,10.854,10.854,10.854,0,0,1-10.854,10.854A10.854,10.854,0,0,1-1701.025,10.854Z" transform="translate(1711 1)" strokeWidth="2" /></svg>
              <span>Profile</span>
            </AniLink>
          </li>
          <li>
            <MenuToggle />
          </li>
        </ul>


        <ul className="spToggleLinks">
          <li className="spToggleLinks_link">
            <AniLink fade duration={1} to={`/categories`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330.001 241"><path d="M-8062,241a20,20,0,0,1-20-20V20a20,20,0,0,1,20-20h85c8.951,0,23.1,5.881,49.426,39H-7772a20,20,0,0,1,20,20V221a20,20,0,0,1-20,20Z" transform="translate(8082)" /></svg>
              <span>Categories</span>
            </AniLink>
          </li>
          <li className="spToggleLinks_link">
            <a className="spToggleLinks_fump" href="https://fump.tech" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288.485 288.482"><g transform="translate(0.001 -0.003)"><path d="M373.07,63.744A78.73,78.73,0,0,0,240.035,23.07L202.707,60.4a19.28,19.28,0,0,0,27.266,27.266L267.3,50.318a40.228,40.228,0,1,1,56.882,56.9l-37.328,37.319A19.277,19.277,0,0,0,314.111,171.8l37.328-37.337A78.691,78.691,0,0,0,373.07,63.744Z" transform="translate(-86.025 0)" /><path d="M144.546,286.863,107.209,324.2a40.187,40.187,0,0,1-36.14,11.049A40.031,40.031,0,0,1,50.327,324.2a40.185,40.185,0,0,1-8.839-13.3,40.145,40.145,0,0,1,8.839-43.6l37.337-37.328A19.273,19.273,0,1,0,60.4,202.725L23.071,240.044a78.775,78.775,0,1,0,111.4,111.4L171.8,314.111a19.27,19.27,0,0,0-27.257-27.248Z" transform="translate(0 -86.035)" /><path d="M262.585,165.2a19.288,19.288,0,0,0-27.257,0L165.17,235.347a19.273,19.273,0,1,0,27.266,27.248l70.149-70.149A19.246,19.246,0,0,0,262.585,165.2Z" transform="translate(-69.644 -69.655)" /></g></svg>
              <span>Fump</span>
            </a>
          </li>
          <li className="spToggleLinks_link">
            <a className="spToggleLinks_twitter" href="https://twitter.com/kentaro_koga" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>
              <span>Twitter</span>
            </a>
          </li>
          <li className="spToggleLinks_link">
            <a className="spToggleLinks_insta" href="https://www.instagram.com/taroken1210/" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg>
              <span>Instagram</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default spNav